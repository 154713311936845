@import 'styles/theme.scss';

.publish-footer {
  --margin: 3px;
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(4, 3, 49, 0.15);
  z-index: 20;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: var(--margin) !important;
  background-color: $white-color;
  color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .proposal-text {
    display: none;
    position: relative;
    flex-grow: 1;

    .proposal-name,
    .client-name {
      display: flex;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      p {
        margin-right: 5px;
      }
    }
  }

  &.section-rtl {
    .proposal-text {
      .proposal-name {
        h1 {
          p {
            direction: rtl;
          }
        }
      }
    }
  }

  .spacer {
    flex-grow: 1;
  }
  .proposal-name {
    max-width: 100%;

    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: $signature-color;
    p {
      margin-bottom: 0;
      &:last-child {
        max-width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .client-name {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: $primary-color-text;
    margin-bottom: 0;
  }
  .signed-text,
  .signed-text-info,
  .paid-text {
    color: $section-color;
    font-size: 13px;
    letter-spacing: 1px;
    margin-left: 20px;
  }

  .signed-text-info {
    text-align: center;
  }

  .ant-btn {
    border-radius: 4px;
    height: 44px;
    border: none;
    padding: 10px 8px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: $white-color;
    letter-spacing: 0.5px;
    &.contact-btn {
      background-color: $publish-background;
      color: $section-color;
    }

    &.sign-proposal-btn,
    &.signature-button {
      background-color: $section-color;
      color: white;
    }

    span {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .footer-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 100%;
      margin: var(--margin);
    }
    &.buttons-1 {
      justify-content: center;
    }
    &.buttons-2,
    &.buttons-3 {
      @include rwd(767) {
        flex-wrap: wrap;

        button:nth-child(1) {
          width: calc(50% - calc(var(--margin) + 6px));
        }
        button:nth-child(2) {
          width: calc(50% - var(--margin));
        }
      }
    }

    button:last-child {
      background-color: #005aff;
      color: white;
    }
  }

  @include rwd-min(768) {
    --margin: 10px;

    .footer-buttons {
      justify-content: flex-end !important;

      &.buttons-1 {
        button {
          max-width: 350px;
        }
      }
    }
  }
  @include rwd-min(992) {
    .proposal-text {
      display: block;
      padding: 0 var(--margin);
    }

    .footer-buttons {
      width: 500px;
    }
  }
  @include rwd-min(1200) {
    .footer-buttons {
      width: 700px;
      button {
        font-size: 14px;
        max-width: 280px !important;
      }
    }
  }
}

.footer-buttons-popover {
  width: 14em;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0px;
    .ant-list-item {
      padding: 5px 16px;
      padding-top: 15px;
      cursor: pointer;
      &:hover {
        background: $tab-background;
      }
      .ant-list-item-meta-title {
        margin-top: 2px;
        font-size: 16px;
        font-weight: 300;
        color: $primary-color-text;
      }
    }
  }
}


.display-rtl {
  .publish-footer {
    direction: rtl;
  }
}