@import "styles/theme.scss";

.team-member-info-container {
  .team-member-info-content {
    position: relative;
    text-align: center;
    padding: 2rem;

    .logo {
      margin-top: 1.063rem;
    }
    .color-divider {
      background: #f03f3b;
      width: 35px;
      height: 1px;
      padding: 0 10px;
      margin: 0 auto;
    }
    .ant-form-vertical {
      text-align: start;
    }

    .info-submit-button {
      font-weight: 600;
    }

    .ant-btn.invite-button {
      margin-top: 4.375rem;
      width: 100%;
      height: auto;
      background: $tab-background;
      color: $primary-color;
      height: 44px;
    }

    .ant-btn.submit-this-button {
      margin-top: 1rem;
      width: 100%;
      height: auto;
      height: 44px;
      border-radius: 6px;
    }

    .team-member-info-info {
      font-size: 18px;
      margin: 2rem 4rem;
      color: $primary-color-text;

      h3 {
        color:  #05034D;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .ant-row {
      flex-direction: column;
    }

    .text-center {
      text-align: center;
    }

    .ant-btn-dangerous {
      box-shadow: 0 10px 15px -4px rgba(241, 92, 60, .4)
    }
  }
}