.totp-verification {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 40vw;
  min-width: 350px;

  .form {
    width: 380px;
  }

  .title {
    margin-top: 0;
  }

  p {
    margin-top: 4px;
    color: #05034D;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }

  .margin-top {
    margin-top: 1.5rem;
  }

  #inputs {
    input {
      width: 50px;
      height: 42px;
      font-size: 18px;
      text-align: center;
      border-radius: 4px;
      border: 1.5px solid #E2E3E8;
      margin: 0 6px;

      &:hover {
        border: 1.5px solid #dedfe3;
      }

      &:focus {
        border: 1.5px solid #40a9ff;
        outline: none;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
      }
    }
  }

  .recovery-input {
    height: 42px;
    border-radius: 4px;
    &:hover {
      border: 1.5px solid #dedfe3;
    }

    &:focus {
      border: 1.5px solid #40a9ff;
      outline: none;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
    }
  }

  .verification-button {
    width: 250px;
    height: 44px !important;
    letter-spacing: 2px;
    margin-top: 24px;
    padding: 12px 16px;
    border-radius: 4px;
    text-transform: uppercase;
    border: none;
    color: white;
    background-color: #f03f3b;
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
      background-color: #f03f3b;
    }
  }

  .remember-browser {
    text-align: center;
    margin-top: 1rem;

    span {
      color: #05034D;
      font-weight: 300;
      ;
    }
  }

  .button-wrapper {
    padding: 0 !important;

    button {
      width: 100%;
    }
  }
}