@import 'styles/theme.scss';

.ant-row.tablist-container {
  width: 100%;
  flex-flow: row;
  flex-flow: row;
  height: 100%;
  background: white;
  margin: 0 !important;

  .ant-row {
    margin: 0 !important;
  }
  .ant-col {
    padding: 0 !important;
  }

  .tablist-prelist-container {
    height: 100%;
    overflow: hidden;
    border-right: 1px solid $tab-border;

    .tablist-prelist {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .tablist-prelist-input {
        height: 5em;
        padding: 1em;
        border-bottom: 1px solid $tab-border;

        .input-container {
          margin-bottom: 0;
          width: 100%;
          .ant-input-affix-wrapper {
            height: 3em;
            order: 1.02062px solid $tab-border;
            border-radius: 4px;
            &:hover {
              border-color: $primary-color-text;
            }
            .ant-input-suffix {
              cursor: pointer;
            }

            @include rwd(320) {
              padding: 4px 0;
              font-size: 12px;
            }
          }
        }
      }

      .tablist-prelist-content {
        @include custom-scrollbar;
        flex-flow: column;
        flex : 1;
        overflow: auto;
        direction: rtl;
        max-height: calc(100vh - 310px);

        .ant-list {
          border-left: 1px solid $tab-border;

          .tablist-prelist-load-more {
            display: flex;
            padding: 4px 8px 4px 4px;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            background-color: $tab-dark-background;
            border-color: $tab-dark-background;
            border-radius: 44px;
            color: $label-color-light;
            height: 2em;
            font-weight: 600;
            font-size: $sub-font-size;
            line-height: 140%;

            &:hover {
              border-color: $tab-dark-background;
            }
          }
        }

        .ant-list > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
          direction: ltr;
          padding-bottom: 10px;

          .ant-list-item {
            margin: 1em;
            margin-bottom: 0.5em;
            padding: 0.7em;
            background: $tab-background;
            border-radius: 4px;
            border: none;
            cursor: pointer;
            h4 {
              color: $primary-color-dark;
              font-weight: 400;
              margin: 0;
              word-wrap: none;
              word-break: break-word;
              img {
                margin-right: 0.6em;
              }
            }
            .anticon {
              margin: 3px;
            }
            .ant-divider {
              display: none;
            }
          }

          .ant-list-items {
            margin-bottom: 0;
          }

          .ant-list-item {
            &.tablist-prelist-inner {
              height: 1.8em;
              background-color: $label-color-light;
              .ant-list-item-meta-title {
                font-size: 12px;
                color: white;
              }
              .anticon svg {
                fill: white;
                width: 0.75em;
                height: 0.75em;
              }
            }

            &.selected {
              background: white;
              border: 1px solid $primary-color-dark;
              box-sizing: border-box;
              border-radius: 4px;
              .ant-divider {
                display: block;
                margin: 0px 5px;
                height: 1.2em;
              }
            }
            &.disabled {
              display: none;
            }
          }
        }
      }
    }
  }

  .input-container {
    width: auto;
  }

  .tablist-content {
    @include custom-scrollbar;
    overflow-y: auto;

    .input-suffix-text {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }

    .price-options {
      display: flex;
      padding: 0.5em 0.25em;
      // justify-content: flex-end;
      .select-container {
        width: calc(100% - 30px);

        .price-options-dropdown {
          width: 100%;
        }
        .ant-select-selector {
          height: 35px !important;
          width: 100%;
          border-radius: 4px;
          .ant-select-selection-item {
            padding: 3px 13px 3px 0;
          }
        }
      }
      .tablist-input-options {
        cursor: pointer;
        font-size: 27px;
        transform: translate(5px, 2px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
      }
      .edit-icon{
        height: 24px;
        min-width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        &:hover{
          background: $primary-color-01-light;
        }
        > svg{
          height: 14px;
          width: 14px;
        }
      }
    }

    .tablist-inactive-tab {
      display: none !important;
    }

    .ant-tabs-nav-disable {
      .ant-tabs {
        margin-bottom: 1em;
      }
      .ant-tabs-nav {
        display: none;
      }
    }

    // based on available inputs render the width - for table view
    .price-inputs {
      flex: 0 0 300px;
    }
    .tablist-quantity {
      .price-inputs {
        flex: 0 0 420px;
      }
    }
    .tablist-recurring {
      .price-inputs {
        flex: 0 0 500px;
      }
    }

    // for value view
    .price-inputs.inputs-value {
      flex: 0 0 30px;
      &.inputs-quantity {
        flex: 0 0 170px;
      }
    }

    .tablist-sortlist-container {
      display: flex;
      margin: 0 0 0.5em 0em;
      justify-content: center;

      &:first-child {
        margin-top: 1em;
      }

      .tablist-sortlist-content {
        width: 100%;
        border: 1px solid $tab-border;
        box-sizing: border-box;
        border-radius: 4px;
        background: white;
        margin: 0em 0.5em 0em 0em;
      }

      .handle {
        flex-direction: column;
        justify-content: center;
        display: flex;
        width: 15px;
        font-size: 30px;
        cursor: move;
        cursor: -webkit-grabbing;
        .anticon {
          padding-bottom: 2px;
        }
      }
    }

    // based on available inputs render the width - for table view
    .price-inputs {
      flex: 0 0 300px;
    }
    .tablist-quantity {
      .price-inputs {
        flex: 0 0 420px;
      }
    }
    .tablist-recurring {
      .price-inputs {
        flex: 0 0 500px;
      }
    }

    // for value view
    .price-inputs.inputs-value {
      flex: 0 0 30px;
      &.inputs-quantity {
        flex: 0 0 170px;
        &.inputs-recurring {
          flex: 0 0 290px;
        }
      }
    }

    .tablist-input-container {
      display: flex;
      justify-content: flex-end;

      .ant-col {
        &:first-child {
          display: flex;
        }
      }

      .tablist-input.super-textarea {
        flex-direction: column;
        margin-bottom: 6px;
        width: calc(100% - 45px);
        label {
          padding-left: 1em;
          padding-top: 0.5em;
          font-size: 10px;
          line-height: 140%;
          display: flex;
          align-items: center;
          color: $label-color-light;
        }
        textarea {
          color: $primary-color-dark;
          min-height: 36px;
          height: 36px;
          &:focus {
            border-right-width: 0;
            box-shadow: none;
          }
        }
      }

      .ant-checkbox-wrapper {
        margin: auto 0.5em;
        &.ant-checkbox-wrapper-disabled {
          .ant-checkbox-disabled {
            .ant-checkbox-inner::after {
              border-color: white;
            }
          }
          .ant-checkbox-disabled:not(.ant-checkbox-checked) {
            .ant-checkbox-inner {
              &:before,
              &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2px;
                height: 18px;
                background-color: lightgrey;
                transform: rotate(45deg) translate(-50%, -50%);
                transform-origin: top left;
                content: '';
                opacity: 1;
                border-color: lightgrey;
              }
              &::after {
                transform: rotate(-45deg) translate(-50%, -50%);
              }
            }
          }
        }
      }

      .tablist-input {
        display: flex;
        margin-bottom: 0;
        textarea,
        input {
          @include custom-scrollbar;
          border-style: none;
          border-color: Transparent;
          overflow: auto;
          height: 35px;
          color: $primary-color-dark;
          &:focus {
            outline: none;
            box-shadow: none;
            border-right-width: 0;
            border-color: $tab-border;
          }
          &:hover {
            border-color: $tab-border;
          }
        }
      }

      .item-name-wrap {
        .tablist-input {
          margin-top: 0.5em;
          width: 100%;
        }
      }

      .ant-col {
        .ant-row {
          flex-flow: row;
          .tablist-price {
            input {
              -moz-appearance: textfield;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
            .input-suffix-text {
              padding: 0.5em 0.25em;
            }
          }
        }
      }

      .tablist-price {
        .input-suffix-text {
          padding: 0 0.25em;
          line-height: 35px;
        }
      }

      .inputs-table {
        display: flex;
        justify-content: flex-end;
      }

      .price-inputs {
        display: flex;
        justify-content: flex-end;
        .ant-col {
          &:first-child {
            flex-direction: column;
            width: 60%;
            .ant-row:first-child {
              justify-content: flex-end;
            }
          }
        }

        .quantity input,
        .items input {
          padding: 4px 5px;
          &::placeholder {
            font-size: 0.9em;
          }
        }
      }

      .price,
      .miles-time,
      .tablist-quantity,
      .tablist-items {
        padding: 0.5em 0.25em;
        input {
          border: 1px solid $tab-border;
          box-sizing: border-box;
          border-radius: 4px;
          height: 2.5em;
          // width: 90px;
        }
        .input-container.input-number-container {
          margin-bottom: 0;
        }
        .input-suffix-text {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 0;
        }
      }
      .price {
        display: flex;
        .currency {
          padding: 7px 2px 0 0;
        }
      }

      .miles-time {
        input {
          width: 100%;
          min-width: 100px;
        }
      }

      .miles-input {
        width: 60%;
        margin: 0.5em 0;
      }
      .miles-time {
        width: 40%;
      }

      .tablist-select-dropdown {
        display: flex;
        justify-content: space-between;
        width: 40%;
        .select-container {
          width: calc(100% - 40px);
        }
        .ant-select-selector {
          height: 35px !important;
        }
      }

      .input-prefix-text {
        transform: unset;
        position: relative;
        padding: 0.1em;

        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 12px;
        margin-bottom: 0;
      }

      .tablist-discount {
        display: flex;
        padding: 0.5em 0 0;
        position: relative;
        margin-bottom: 0;
        &.input-container {
          direction: unset !important;
        }

        input {
          border: 1px solid $tab-border;
          height: 2.5em;
          margin-left: auto;
        }

        .input-suffix-text {
          padding: 0.8em 0.5em;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 0;
          position: absolute;
          right: 0;
          transform: translateX(100%);
          line-height: 12px;
          span {
            padding-right: 5px;
            color: $tab-border;
            cursor: pointer;
            &.suffix-active {
              color: $primary-color-text;
            }
          }
        }

        .hidden-currency {
          opacity: 0;
          padding: 0 0.25 !important;
          visibility: hidden;
        }
        .input-discount-delete {
          position: absolute;
          right: 4px;
          z-index: 9;
        }
      }

      .input-discount-delete {
        height: 32px;
        width: 32px;
        position: absolute;
        bottom: 8px;
        right: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .tablist-select-dropdown {
        display: flex;
        justify-content: space-between;
        width: 40%;
        .select-container {
          width: calc(100% - 40px);
        }
        .ant-select-selector {
          height: 35px !important;
        }
      }

      .price-options-dropdown {
        width: 100%;
        .ant-select-selector {
          height: 35px !important;
          width: 100%;
          border-radius: 4px;
        }
      }

      .tablist-input-options {
        cursor: pointer;
        font-size: 27px;
        transform: translate(1px, 2px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 5px;
      }
    }

    .more-icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
    }
    .super-sortlist {
      .anticon-more {
        @extend .more-icon;
        margin-top: 9px;
      }
    }
    .miles-sortlist {
      .anticon-more {
        @extend .more-icon;
        margin: 4px 0 0 -7px;
      }
    }

    .tablist-input-header {
      display: flex;
      margin: 0em 0.5em 1em 1em !important;
      background: $background-color;
      border-radius: 4px;
      padding: 4px 0;
      justify-content: flex-end;

      .ant-col:last-child {
        display: flex;
        justify-content: flex-end;
      }

      .tablist-label {
        padding: 0 0.25em !important;
        color: $primary-color-dark;
        @media screen and (max-width: 916px) {
          &.deliverable {
            margin-bottom: 0.5em;
          }
        }
      }

      .deliverable {
        padding-left: 10px !important;
      }

      .tablist-input-label {
        margin: auto 0;
        padding: 0 1em !important;
        color: $primary-color-text;
      }

      &:not(.structured) .deliverable {
        padding-left: 10px !important;
      }

      .input-container {
        margin: unset;
      }
      .borderless-input {
        background-color: transparent;
        border: unset;
        box-shadow: unset;
        resize: none;
        padding: 4px 4px 4px 0 !important;
      }
      &.structured {
        .borderless-input:not(.flush) {
          padding: 4px 6px !important;
          border: 1px solid $primary-color-light;
        }
      }

      .input-container {
        margin: unset;
      }
      .borderless-input {
        background-color: transparent;
        border: unset;
        box-shadow: unset;
        resize: none;
        padding: 4px 4px 4px 0 !important;
      }
      &.structured {
        .borderless-input:not(.flush) {
          border: 1px solid $primary-color-light;
          padding: 4px 6px !important;
        }
      }
    }

    .tablist-sortlist-container
      .tablist-sortlist-content
      .tablist-input-description.input-container {
      margin: 0 0.72em 0.72em;
      width: auto !important;
      background: #ffffff;
      border: 1.02062px solid $tab-border;
      border-radius: 4.08247px;

      textarea {
        @include custom-scrollbar;
        min-height: 38px;
        border: 0;
        color: $primary-color-dark;
        line-height: 140%;
        padding: 0.72em;

        &:focus {
          outline: none;
          box-shadow: none;
          border-right-width: 0;
          border-color: $tab-border;
        }
        &:hover {
          border-color: $tab-border;
        }
      }

      &:focus {
        outline: none;
      }
    }

    .sortlist-options-container {
      justify-content: space-between;
      padding: 1em 0.4em 1em 1.2em !important;
      .ant-col {
        &:first-child {
          display: flex;
          .ant-select {
            width: 100px;
            height: 32px;
          }
        }
      }
      button {
        margin: 0em 0em 1em 1em;
        color: $label-color-light;
        max-width: 15em;
        min-width: 5em;
        &.ant-btn-round {
          padding: 4px 12px;
          display: flex;
          justify-content: space-evenly;
          span.anticon {
            padding: 4px 0;
          }
        }
        &.ant-btn-round > .anticon + span,
        &.ant-btn-round > span + .anticon {
          margin-left: 5px;
        }
      }

      .button-inactive {
        background: $background-color;
      }
      .button-disabled {
        background-color: $input-disabled-color;
        cursor: not-allowed;
        border-color: $border-color-base !important;
      }

      .ant-radio-group {
        padding: 2px;
        background: $tab-background;
        border-radius: 4px;
        .ant-radio-button-wrapper {
          border-right-color: $tab-background;
          border-color: $tab-background;
          background: $tab-background;
          color: $primary-color;
          border-radius: 4px;
          &:focus,
          &:focus-within {
            outline: none;
            box-shadow: none;
            color: $primary-color-dark;
          }
          &::before {
            background-color: white;
          }
        }
        .ant-radio-button-wrapper-checked {
          border-right-color: $tab-background;
          border-color: $tab-background;
          background: white;
          color: $label-color-light;
          font-weight: 600;
        }
      }

      .ant-select-single {
        .ant-select-selection-item {
          color: $primary-color;
        }
      }

      .currency-select {
        .ant-select-selector {
          width: 100px !important;
          padding: 0;
          height: 32px !important;
        }
        .ant-select-selection-item {
          padding-left: 6px !important;
          line-height: 32px;
        }
        .ant-select-selection-search {
          width: 100%;
          left: 0;
          .ant-select-selection-item,
          input {
            padding: 0 17px 0 6px !important;
          }
        }
        .ant-select-arrow {
          position: absolute;
          right: 6px;
          top: calc(50% - 0) !important;
        }
      }
    }

    .ant-tabs-nav-enable {
      .ant-empty {
        margin-top: 65px;
        height: 100%;
        .ant-empty-image {
          height: 100%;
        }
        .ant-empty-description {
          color: $primary-color-text;
          max-width: 350px;
          margin: auto;
        }
      }
      .ant-tabs-top {
        margin-top: 1em;
        padding: 1em 0.4em 1em 1.2em !important;

        .ant-tabs-nav {
          margin: 0;
          .ant-tabs-nav-wrap {
            background-color: white;
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                background-color: white;
                border: none;
                opacity: 0.8;
                .ant-tabs-tab-btn {
                  font-size: $label-font-size;
                  font-weight: 600;
                  letter-spacing: 0.04em;
                  align-items: center;
                  color: $label-color-light;
                }
              }
              .ant-tabs-tab-active {
                border: none;
                background-color: $tab-background;
                opacity: 1;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                .ant-tabs-tab-btn {
                  color: $primary-color-dark;
                }
              }
              .ant-tabs-tab:after {
                content: '';
                height: 45%;
                margin: 12px 0px;
                width: 1px;
                position: absolute;
                right: 0;
                top: 0;
                background-color: $tab-border;
              }
              .ant-tabs-tab-active::after {
                height: 0;
              }
              .ant-tabs-nav-add {
                background: white;
                border: none;
                .anticon {
                  border: none;
                  padding: 0.2em;
                  background: $tab-background;
                }
              }
            }
          }
        }

        .ant-tabs-content-holder {
          background: $tab-background;
          border-radius: 4px;
          padding: 2.5em 0em;
          .ant-tabs-content > .ant-tabs-tabpane {
            .tablist-sortlist-container > .tablist-sortlist-content {
              background-color: white;
              .tablist-input {
                input {
                  color: $primary-color-dark;
                }
              }
            }
          }
        }
      }
    }

    .sortlist-container {
      position: relative;
      .sortlist-price-delete {
        background: #ffffff;
        box-shadow: $box-shadow;
        border-radius: 4px;
        width: 1.7em;
        height: 1.7em;
        text-align: center;
        cursor: pointer;
        position: absolute;
        right: 1em;
        top: -2em;
      }

      .sortlist-price-fields {
        padding: 0 1.5em;
        margin-bottom: 2em;
        display: flex;
        .input-container {
          margin: 1em 0em;
          width: 40% !important;
          input {
            color: $primary-color-dark;
          }
          label {
            line-height: 2.4em;
          }
        }
        .select-container {
          margin: 1em;
          width: 40%;
          label {
            margin: 0 1em;
            color: $primary-color-dark;
          }
          .ant-select {
            .ant-select-selector {
              background: #ffffff;
              border: 1px solid $tab-border;
              box-sizing: border-box;
              border-radius: 4px;
              color: $primary-color;
            }
          }
        }
      }
    }

    .totals-popover-container {
      position: sticky;
      justify-content: flex-end;
      display: flex;
      bottom: 0;
      float: right;
      z-index: 1;
      padding-bottom: 8px;
      .totals-container-tag {
        background: #ffffff;
        box-shadow: 0px 0px 15px -2px rgba(3, 20, 63, 0.2);
        border-radius: 68px;
        min-width: 18em;
        max-width: 30em;
        height: 4.5em;
        padding: 1em;
        display: flex;

        .anticon {
          padding: 0em 1em;
        }
        .totals-container-tag-info {
          display: flex;
          p {
            padding: 0.3em 1em;
            font-size: $label-font-size;
            line-height: 1;
            display: flex;
            align-items: center;
            color: $label-color-light;
            margin: 0;
          }
          .totals-container-tag-price {
            color: $primary-color-dark;
            font-size: $medium-text-font-size;
          }
          &:before {
            content: '';
            height: 50%;
            margin: 1em 0px;
            width: 0.1em;
            position: absolute;
            top: 0;
            background-color: $tab-border;
          }
        }
      }
    }
  }

  // when "price" & "payment-type" is visible
  .tablist-active-tab {
    .price {
      width: 45%;
    }
    .price-options {
      width: 55%;
    }

    &.tablist-quantity {
      .items {
        width: 30%;
      }
      .price {
        width: 30%;
      }
      .price-options {
        width: 40%;
      }
    }
    &.tablist-recurring {
      .quantity {
        width: 24%;
      }
      .items {
        width: 24%;
      }
      .price {
        width: 24%;
      }
      .price-options {
        width: 28%;
      }
    }
  }
}

.totals-popover {
  margin-left: -10px;
  .ant-popover-inner-content {
    padding: 0;
    .totals-container {
      bottom: 0;
      width: 100%;
      left: 0;
      margin: 0em;
      background: white;
      position: relative;
      max-width: 100%;
      min-height: 1px;
      .totals-container-close {
        position: absolute;
        top: 0;
        left: 0;
        justify-content: flex-end;
        display: flex;
        width: 100%;
        height: 0.2em;
        background: $secondary-color;
        .totals-container-close-button {
          background: $secondary-color;
          border-radius: 4px 4px 0px 0px;
          width: 7em;
          margin: 0 auto;
          top: -1.5em;
          position: absolute;
          text-align: center;
          cursor: pointer;
          font-size: $label-font-size;
          color: white;
          border: none;
        }
      }
      .totals-content {
        padding: 0.5em;
        width: 405px;
        .totals-content-container {
          margin-bottom: 0.5em;
          flex-wrap: nowrap;
          .totals-info {
            width: auto;
            padding-right: 1em;
            display: flex;
            justify-content: flex-end;
            .input-container {
              display: inline-block;
              width: auto;
              min-width: 210px;
              max-width: 210px;
              margin-bottom: 0;

              &:focus {
                outline: none;
              }
              .total-text,
              .dis-text,
              .tax-text,
              .sub-total-text {
                border: none;
                text-align: end;
                padding: 0.5em;
                color: $label-color-light;
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .totals-content {
            width: 8.5em;
            padding: 0.3em 0;
            display: flex;
            color: $primary-color-text;
            .input-container {
              width: auto;
              margin-bottom: 0;
              input {
                border: none;
                border-bottom: 1px solid;
                border-radius: 0;
                text-align: end;
                color: $primary-color-text;
              }
            }
            .totals-content-unit {
              font-size: $label-font-size;
              line-height: 2.5em;
              color: $primary-color-text;
              opacity: 0.2;
              cursor: pointer;
            }
            .ant-select {
              width: 4em;
            }
            .totals-content-currency {
              width: 100%;
              font-size: $medium-text-font-size;
              color: $primary-color-text;
              &::placeholder {
                font-size: 14px;
              }
            }
          }
          .totals-currency {
            line-height: 35px;
          }
        }
        .ant-divider-dashed {
          margin: 0.5em 0em;
          border: 1px dashed $primary-color;
        }
      }
    }
  }
}

.drawer-popover {
  position: absolute;
  right: auto;
  left: -10px;
}

.tablist-options-dropdown-menu {
  width: 16em;
  padding: 10px 0;
  .ant-dropdown-menu-item {
    padding: 8px 12px;
    color: $primary-color-text;
    display: flex;
    align-items: center;
  }
}

@include rwd(1320) {
  .ant-row.tablist-container {
    .tablist-content {
      .sortlist-options-container {
        button {
          margin: 0em 0em 1em 0.5em;
          min-width: 3em;
          &.ant-btn-round {
            padding: 4px 8px;
          }
        }
      }
    }
  }
}

@include rwd(1200) {
  .ant-row.tablist-container {
    .tablist-content {
      .ant-radio-group {
        .ant-radio-button-wrapper {
          padding: 0 8px;
        }
      }
    }
  }
}

.wizard-content-container {
  .ant-row.tablist-container {
    .tablist-content {
      .ant-tabs-nav-disable {
        .ant-tabs {
          .tablist-sortlist-container {
            .handle {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}

.ant-modal-body {
  .ant-row.tablist-container {
    .tablist-content {
      .ant-tabs-nav-disable {
        .ant-tabs {
          .tablist-sortlist-container {
            .handle {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}

/*--currency dropdown in tablist options--*/
.tablist-currency-menu {
  background-color: #fff;
  .ant-select-item {
    padding: 6px !important;
  }
  .ant-divider-horizontal {
    margin: 6px 0 !important;
  }
  .ant-select-item-empty {
    padding: 0;
  }
  .add-button,
  .custom-button {
    padding: 0;
    border: unset;
    font-size: 0.9em;
    width: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: $primary-color;
    }
  }
  .input-wrap {
    display: flex;
    position: relative;
    input {
      padding-right: 32px;
      padding-left: 6px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
  .ant-select-item {
    .custom-currency-suffix {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      background: #fff;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .ant-spin {
        height: 14px;
      }
    }
    &:hover {
      .custom-currency-suffix {
        display: flex;
      }
    }
  }
  .add-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    background-color: rgba(0, 0, 0, 0.05);
    border-left: 1px solid $border-color-base !important;
    z-index: 2;
  }
}

textarea {
  cursor: auto;
  padding-right: 22px !important;
}

.wizard-language-rtl,
.rich-editor-price-edit-hebrew {
  .price-inputs {
    .price {
      direction: rtl;
      .currency {
        padding: 7px 0 0 2px !important;
      }
    }
  }
  .tablist-discount {
    .input-prefix-text {
      display: none !important;
    }
  }

  .ant-input-clear-icon {
    left: 5px;
    right: unset !important;
  }
  textarea {
    padding-right: 0.72em !important;
    padding-left: 22px !important;
  }

  .tablist-input-container {
    .input-container {
      direction: rtl;
    }
  }
  .tablist-input-header {
    input,
    textarea {
      direction: rtl;
      padding-left: 8px !important;
    }
  }
  .super-textarea {
    textarea {
      text-align: right;
    }
  }
}

.tablist-input-header {
  .price-options {
    align-items: center;
  }
}

.tabList-drawer {
  position: absolute;
  inset: 0;
  z-index: 10;
  max-height: calc(100% - 50px);
  pointer-events: none;

  .tabList-drawer-mask {
    position: absolute;
    inset: 0;
    z-index: 9;
    pointer-events: auto;
  }

  .tabList-drawer-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff;
    pointer-events: auto;
  }

  .tabList-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .tabList-drawer-header {
      display: flex;
      flex: 0;
      align-items: center;
      padding: 16px 24px;
      font-size: 16px;
      line-height: 1.5;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);

      .tabList-drawer-extra {
        flex: none;
      }

      .tabList-drawer-header-title {
        display: flex;
        flex: 1;
        align-items: center;
        min-width: 0;
        min-height: 0;

        .tabList-drawer-title {
          flex: 1;
          margin: 0;
          color: #05034d;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.5;
        }

        .tabList-drawer-close {
          display: inline-block;
          margin-inline-end: 12px;
          color: rgba(0, 0, 0, 0.45);
          font-weight: 600;
          font-size: 16px;
          font-style: normal;
          line-height: 1;
          text-align: center;
          text-transform: none;
          text-decoration: none;
          position: absolute;
          right: 0;
          height: 30px;
          width: 30px;
          border-radius: 5px;
          background: 0 0;
          background-color: #f5f6fa;
          border: 0;
          outline: 0;
          cursor: pointer;
          transition: color 0.2s;
          text-rendering: auto;
        }
      }
    }

    .tabList-drawer-body {
      flex: 1;
      min-width: 0;
      min-height: 0;
      padding: 24px;
      overflow: auto;

      .tabList-checkboxes {
        display: block;
        text-align: left;

        .tabList-checkbox-item {
          justify-content: flex-start;
          margin-bottom: 1em !important;
          flex-wrap: unset;
          align-items: flex-start;

          label {
            span {
              color: #05034d;
            }
          }

          .checkbox-list-info-icon {
            margin: auto 0.3em;
            cursor: pointer;
            position: relative;
            right: 6px;

            svg {
              height: 16px;
              width: 16px;
              border: 1px solid $svg-fill-border-extra-light;
              border-radius: 50px;

              path {
                fill: $svg-fill-border-light;
              }

              circle {
                fill: #ffffff;
              }
            }
          }
        }
      }
    }
    .tabList-drawer-footer {
      padding: 10px;
      display: flex;
      justify-content: flex-end;

      .reset-btn {
        font-weight: 600;
        color: $primary-color-text;
      }
    }
  }
}

.tabList-drawer-right > .tabList-drawer-content-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 260px;
  max-width: 100vw;
  transition: all 0.3s;
  box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12),
    -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}

.title-edit{
  .ant-input{
    margin-bottom:20px;
  }
}