@import 'styles/theme.scss';

.published-proposal-modal {
  max-width: 604px;

  .ant-modal-body {
    text-align: center;
    padding: 2em 2em !important;

    .title {
      margin-top: 24px 0px 20px 0px;
    }

    .divider {
      margin-top: 0;
    }

    .body {
      color: $primary-color-text;
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px 0 0 0 !important;
    }

    .ant-divider {
      margin-top: 24px;
    }

    .ant-divider-inner-text {
      color: $label-color-light;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.48px;
      opacity: 0.8;
    }

    .input-with-copy-btn {
      margin-top: 24px;
      gap: 12px;
      flex-wrap: nowrap;

      .pdf-link-input {
        border: 1px solid $tab-border;
        border-radius: 4px;
        height: 40px;
        color: $primary-color-text;
        max-width: 430px;
        background: $tab-background;
        font-size: 16px;
        font-weight: 400;
        /* 22.4px */
      }

      .copy-btn {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        border-radius: 4px;
        background: $secondary-color;
        border: 0;
        height: auto;
        color: $white-color;
        text-align: center;
        font-size: 14px;
        font-weight: 600;

        .anticon {
          height: 20px;
        }

        span {
          margin: 0;
          text-transform: none;
        }
      }
    }

    .modal-options-block {
      margin-top: 12px;
      justify-content: stretch;
      gap: 12px;

      >.ant-row {
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1;
        border-radius: 4px;
        border: 1px solid $tab-border;
        cursor: pointer;

        .helper-text {
          color: $primary-color-text;
          font-size: 12px;
          font-weight: 400;
          margin-top: 1px;
        }
      }
    }

    .button-wrapper {
      margin-top: 4em;

      button {
        background: $secondary-color;
        color: white;
        border: 2px solid $secondary-color;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 4px;

        &.cancel {
          height: 3em;
          width: 10em;
        }

        &:hover {
          opacity: 0.8;
          background: $secondary-color;
          border-color: $secondary-color;
        }
      }
    }
  }

  .email-settings-wrapper {
    border-radius: 4px;
    border: 1px solid $tab-border;

    .header-row {
      width: 100%;
      padding: 16px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    .header-text {
      gap: 8px;
      align-items: center;

      span {
        color: $primary-color-text;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .action-icon {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        fill: $primary-color-text;
        height: 12px;
      }
    }

    .custom-number-input {
      width: 65px;

      input {
        text-align: center;
      }
    }
  }

  .proposal-email-settings-form {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }

  .reminder-section .text {
    color: $primary-color-text;
    font-size: 14px;
    font-weight: 400;
  }

  .btn-view {
    border-radius: 4px;
    background: #F3F4F9;
    padding: 4px;
    align-self: end;
    margin-left: auto;
    display: flex;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  .email-list {

    div,
    span:not(.anticon) {
      text-align: start;
      color: $primary-color-text;
      font-size: 12px;
      font-weight: 400;
    }

    b {
      font-weight: 600;
    }
  }

  .ant-switch-checked {
    background-color: $primary-color;
  }

  .footer-section {
    .send-proposal-btn {
      display: flex;
      padding: 6px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: $link-color;
      margin-right: 12px;
      border: 0;
      width: auto !important;

      .ant-btn-loading-icon {
        position: unset;
        margin: 0;

        svg {
          fill: $white-color;
        }
      }

      > :last-child {
        color: $white-color;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        margin: 0;
      }

      &[disabled] {
        background: $tab-background;
        opacity: 0.6;

        :last-child {
          color: $link-color;
        }

        svg path {
          stroke: $link-color;
        }
      }
    }

  }
}