@import "styles/theme.scss";

.plans-content .ant-row.plans-info {
  flex-direction: row;
  padding: 0;
  .plans-content-header {
    font-weight: 600;
    color: $primary-color-text;
  }
  > .ant-col {
    padding: 2.3em 4.45em;
    border-right: 1px solid $tab-border;

    &:last-child {
      padding: 2.3em 1.75em;
      border: 0;
    }

    .plans-price {
      margin-bottom: 0;
      color: $primary-color-text;

      &--total-before-discount {
        margin: -1em 0px 0.5em 0;

        span {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          text-decoration: line-through;
          color: #9b9ab8;
          &:before,
          &:after {
            content: "\00a0\00a0";
          }
        }
      }

      &.plans-price-subheading {
        background: $light-color;
        border-radius: 40px;
        width: fit-content;
        margin: 0 auto;
        padding: 0.1em 1em;
        margin-bottom: 0.5em;

        &--discount {
          @extend .plans-price-subheading;
          padding: 0.1em 0.5em;
          color: $primary-color;

          span:last-child {
            margin: 0 0.5em 0 0;
            svg {
              margin: auto;
            }
          }
        }
      }

      span:first-child {
        font-weight: bold;
        font-size: 40px;
        line-height: 62px;
      }

      span:last-child {
        font-size: 12px;
        line-height: 24px;
        color: $primary-color-light;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-bottom: 1em;
        margin-left: 0.5em;
      }
    }

    .plans-price-discount-expiry {
      span {
        font-size: 12px;
        color: $label-color-light;
      }
    }

    .ant-divider {
      color: $tab-border;
    }

    .plans-type {
      & > * {
        margin: 0 0.2em;
      }

      .ant-switch-checked {
        background-color: $primary-color-text;
      }

      span {
        color: $primary-color-text;
        line-height: 21px;
        font-size: 14px;
      }

      span.active {
        font-weight: 600;
      }

      span:last-child {
        background: #f0f2ff;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 100;
        padding: 0 0.5em;
      }
    }

    .plans-payment-info {
      color: $primary-color-text;
    }

    .plans-payment-buttons {
      margin-top: 1em;

      &.trial {
        background: $light-color;
        color: $primary-color-text;
        width: 80%;
        margin-left: 4em;
        padding: 2em 1em;
      }

      .trial-user-select-plan {
        font-weight: 600;
        p {
          margin-bottom: 0.2em;
        }
        .trial-plan-count {
          margin-bottom: 10px !important;
          font-size: 25px;
        }

        .ant-divider-dashed {
          border-color: $trial-text-underline;
          margin-left: 9em;
          min-width: 0em;
          width: 18%;
          margin-top: -1em;
          margin-bottom: 0px;
        }
        .ant-slider {
          .ant-slider-rail,
          .ant-slider-track,
          .ant-slider-step {
            height: 16px;
            border: 1px solid $slider-border;
            border-radius: 100px;
          }

          .ant-slider-track {
            background: $trail-slider-track;
          }

          .ant-slider-handle {
            background-image: url(https://dr08qaykn9isr.cloudfront.net/images/SliderHandler.svg);
            width: 35px;
            height: 35px;
            margin-top: -9px;
            background-color: none;
            border: none;
            background-size: 35px;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
      flex-direction: column;
      .paypal-buttons,
      .paypal-buttons-disabled > div {
        margin: 1em auto;
        width: 19em !important;
        font-size: 16px !important;
        .paypal-button-tagline {
          display: none;
        }
      }
      & > div:first-child {
        margin: auto;
      }

      button.primary-btn {
        height: 44px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 1em auto;
        width: 19em;

        &.add-users-button {
          background: $secondary-color;
          color: white;
          border: 2px solid $secondary-color;
          box-shadow: $light-box-shadow;
          &:hover,
          &:focus {
            background: $secondary-color;
            color: rgb(255, 255, 255);
            border-width: 2px;
            border-style: solid;
            border-color: transparent;
            border-image: initial;
            text-decoration: none;
          }
        }

        &.plans-switch-button {
          background: $light-color;
          color: $primary-color-text;
          margin-bottom: 1.7em;
          border: 0px $light-color;
          box-shadow: $light-box-shadow;
          &:hover,
          &:focus {
            background: $light-color;
            color: $primary-color-text;
            border-width: 2px;
            border-style: solid;
            border-color: transparent;
            border-image: initial;
            text-decoration: none;
          }
        }

        &.cancel {
          background: none;
          color: $primary-color-text;
          border: none;
          box-shadow: none;
          margin: 0 auto;
          &:hover,
          &:focus {
            background: none !important;
            color: $primary-color-text !important;
          }
        }

        &:disabled {
          opacity: 0.3;
        }
      }
    }

    .plans-information-header {
      font-size: 14px;
      margin-bottom: 1.5em;
      color: $primary-color-text;
      font-weight: 600;
      text-align: initial;
    }

    .plans-information {
      margin: 10px -8px 8px !important;

      > .ant-row {
        justify-content: flex-start;
        flex-direction: row;
        margin-bottom: 0;
      }

      .ant-col:not(:first-child) {
        padding-right: 8px;
      }

      .ant-input-number {
        border: none;
        border-bottom: 2px dashed #5250ab;
        margin: 2em 2em 1.5em 2em !important;

        input {
          text-align: center;
          font-weight: bold;
          font-size: 32px;
          line-height: 32px;
          color: $primary-color-text;
        }
      }

      .ant-slider {
        .ant-slider-rail,
        .ant-slider-track,
        .ant-slider-step {
          height: 16px;
          border: 1px solid #d8d9e2;
          border-radius: 100px;
        }

        .ant-slider-track {
          background: #5250ac;
        }

        .ant-slider-handle {
          background-image: url(https://dr08qaykn9isr.cloudfront.net/images/SliderHandler.svg);
          width: 35px;
          height: 35px;
          margin-top: -9px;
          background-color: none;
          border: none;
          background-size: 35px;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .ant-row {
        &.feature-list-disabled {
          color: #c1bfd2;

          .feature-list-check-icon {
            svg {
              circle {
                fill: #e1e1ea;
              }

              path {
                stroke: #e1e1ea;
              }
            }
          }
        }

        &.feature-list {
          justify-content: flex-start;
          margin-bottom: 2em;
          flex-wrap: unset;
          align-items: flex-start;

          .feature-list-check-icon {
            margin-right: 0.5em;

            svg {
              height: 20px;
              width: 20px;
            }
          }

          .feature-list-info {
            display: inline-block;
            text-align: left;
          }

          .feature-list-info-icon {
            margin: auto 0.3em;
            cursor: pointer;
            svg {
              height: 16px;
              width: 16px;
              border: 1px solid $svg-fill-border-extra-light;
              border-radius: 50px;

              circle {
                fill: #ffffff;
              }

              path {
                fill: $svg-fill-border-light;
              }
            }
          }
        }
      }
    }

    .plans-review {
      justify-content: center;

      .testimony {
        margin: 9em auto 0em auto;
        max-width: 400px;
      }

      .plans-reviewer {
        .plans-reviewer-info {
          flex-direction: column;
          display: flex;
          justify-content: center;
          margin-left: 16px;

          span {
            text-align: initial;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: $primary-color-text;
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $primary-color-text;
      }

      .plans-review-service-icon {
        margin-top: 1em;
        svg {
          width: 100%;
        }
      }
    }
  }
}

.feature-list-info-tooltip {
  color: $primary-color-text;
}

.cancel-plan-text,
.cancel-plan-text-info {
  color: $label-color-light;
  cursor: pointer;
}

.cancel-plan-text-info {
  margin-bottom: 0;
}

.start-new-plan-section {
  width: 80%;
  margin-left: 4em;
  .start-plan-button {
    width: 100%;
    background: $secondary-color;
    color: white;
    border: 2px solid $secondary-color;
    box-shadow: $light-box-shadow;
    font-weight: 600;
    &:hover,
    &:focus {
      background: $secondary-color;
      color: rgb(255, 255, 255);
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      border-image: initial;
      text-decoration: none;
    }
  }
}

.plan-divider {
  border: 1px solid $trial-text-underline !important;
  margin-left: 0 !important;
  margin-right: 0.2em !important;
}
