@import 'styles/theme.scss';

.publish-content {
  position: relative;
   
  .proposalheaderwhite p > *,
  [class*="line-height__"] > * {
    line-height: inherit;
  }

  [class*="line-height__"] > * > span,
  [class*="line-height__"] > * > span > span {
    background-color: inherit;
  }

  &.is-template-preview {
    padding: 0px !important;
    #section-content-container, > div {
      max-width: 100%;
    }
  }
  .section-page-breaker {
    margin: 0px 0px;
    max-width: 100% !important;
    background: none !important;
    width: 100%;
    top: 257px;
    justify-content: center;
    left: 0;
    position: absolute;
    display: flex;

    .section-page-breaker-sides {
      width: 13.45%;
      border-bottom: 1.5px solid #928e8e;

      p {
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }
  &.language-hebrew {
    text-align: right;
  }
  @media (min-width: 992px) {
    &.language-hebrew {
      .ant-col-lg-offset-8 {
          margin-left: 0 !important;
      }
    }
  }
}

.mobile-screen {
  width: 100% !important;
  max-width: 500px !important;
  .ant-col,
  .ant-col-8,
  .ant-col-16 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .form-list-item {
    width: 100% !important;
  }

  .form-list-two-columns {
    .form-list-item {
      &:nth-child(odd) {
        margin-right: 0em !important;
      }

      &:nth-child(even) {
        margin-left: 0em !important;
      }
    }
  }
}

@include sm {
  .desktop-screen {
    zoom: 50%;
    width: 990px !important;
    max-width: 990px !important;

    .ant-col-8 {
      flex: 0 0 33.33333333% !important;
      width: 33.33333333% !important;
      max-width: 33.33333333% !important;
    }

    .ant-col-16 {
      flex: 0 0 66.66666667% !important;
      width: 66.66666667% !important;
      max-width: 66.66666667% !important;
    }
  }
}

.padding-left-none {
  padding-left: 0 !important;
}

.margin-left-none {
  margin-left: 0 !important;
}

.max-width-140 {
  max-width: 140px !important;
}
@media (min-width: 992px) {
  .max-width-28p {
    flex: 0 0 28% !important;
    max-width: 28% !important;
  }
  .max-width-84p {
    max-width: 84% !important;
  }
}

.font-size-28 {
  font-size: 28px !important;
}

@media screen and (max-width: 768px) {
  .mobile-screen ,
  .desktop-screen {
    .ant-col {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

// for the icon positioning
.chatway--container .chatway--trigger-container {
  bottom: 95px !important;
  right: 20px !important;
}

//for the widget positioning
.chatway--container .chatway--frame-container {
  bottom: 180px !important;
  right: 20px !important;
}

@include rwd(767) {
  // for the icon positioning
  .chatway--container .chatway--trigger-container {
    bottom: 70px !important;
    right: 20px !important;
  }  

  //for the widget positioning
  .chatway--container .chatway--frame-container {
    bottom: 140px !important;
    right: 20px !important;
  }
}

@include rwd(1441) {
  .content-wrapper-preview {
    right: 100px
  }
}


@include rwd(1198) {
  .content-wrapper-preview {
    right: 180px
  }
}

@include rwd(1100) {
  .content-wrapper-preview {
    right: 225px
  }
}

@include rwd(998) {
  .content-wrapper-preview {
    right: 248px
  }
}

@include rwd(1441) {
  .content-wrapper-preview-left {
    left: 100px
  }
}


@include rwd(1198) {
  .content-wrapper-preview-left {
    left: 180px
  }
}

@include rwd(1100) {
  .content-wrapper-preview-left {
    left: 225px
  }
}

@include rwd(998) {
  .content-wrapper-preview-left {
    left: 248px
  }
}