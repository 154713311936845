@import "styles/theme.scss";

.dashboard-filter {
  .anticon.search-suffix-hidden {
    display: none;
  }
  
  .item-filter-menu {
    margin-top: 2em;
  }

  .item-filter-title {
    font-weight: 600;
    font-size: 24px;
    color: $primary-color-dark;
  }

  .search-filter-col {
    padding: 4px;
    justify-content: flex-start;
    display: flex;
  }

  .item-filter-value {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    color: #4b4a81;
    span:last-child {
      margin-left: 4px;
    }
  }

  .ant-picker {
    padding-left: 0px !important;
  }

  .date-picker-col {
    padding: 4px;
    .ant-picker-suffix {
      cursor: pointer;
    }
  }

  .ant-picker.date-picker {
    width: 100%;
    border: 0;
    border-bottom: 2px solid;
    border-color: $background-color !important;

    .ant-picker-input {
      input {
        text-align: left;
        font-size: 18px !important;
        font-weight: 300 !important;
        color: $primary-color-text;
      }

      .ant-picker-clear {
        padding: 2px 3px 2px 4px;
        background: $border-color;
      }
    }

    .ant-picker-active-bar {
      margin-left: 0px;
    }
  }

  .search-filter {
    .ant-space-item {
      height: 24px;
      margin-right: 0px !important;
    }
  }

  .search-input-row {
    display: flex;
    height: 24px;

    .anticon {
      margin-left: -5px;
      z-index: 11;
    }
  }

  .icons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @include sm {
      justify-content: center;
    }

    .ant-dropdown-trigger {
      display: flex !important;
      align-items: center;
      .proposal-sorting {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      .sort-text {
        display: inline-block;
        max-width: 150px;
        background: $button-icon-hover;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-right: 1em;
        padding: 5px 8px;
        padding-left: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #05034D;
        cursor: pointer;
      }
    }

    .search-proposal {
      border-radius: 6px;
      border: none;
      transition: max-width 500ms, opacity 500ms;
      max-width: 0;
      opacity: 0;

      &.showInput {
        max-width: 383px;
        opacity: 1;
        .anticon {
          margin-right: 0em;
        }
        .ant-input-suffix {
          .anticon {
            &.anticon-close-circle {
              &.ant-input-clear-icon {
                padding-top: 7px !important;
                font-size: 18px !important;
              }
            }
          }
        }
      }
    }

    .search-proposal:hover,
    .search-proposal:focus {
      border-color: $border-color important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      box-shadow: none;
    }

    .vertical-divider {
      height: 1.1em;
      border-left: 1px solid $tab-border;
    }

    .content-search-icon {
      transition: transform 500ms, opacity 500ms, max-width 500ms;
      opacity: 1;
      max-width: 80px;
      z-index: 1;
      &.hide-search {
        opacity: 0;
        transform: scale(0);
        max-width: 0;
      }

      &:hover {
        background: $button-icon-hover;
        border-radius: 3px;
      }
    }

    .anticon {
      margin-right: 1em;
      height: 32px;
      width: 32px;
      padding: 0.45em;
      cursor: pointer;

      &.trash-enabled {
        opacity: 0.5;
        cursor: not-allowed !important;
      }

      &:hover,
      &.anticon-active {
        background: $button-icon-hover;
        border-radius: 6px;
      }

      &.status-filter {
        padding: 0;
      }

      &.proposal-sorting {
        padding: 0;
        margin-right: 0;
        &.round-edges {
          border-radius: 6px !important;
          margin-right: 10px !important;
        }
      }

      &.download-report-icon {
        padding: 0;
        margin-left: 0.45em;
      }
      &.trash-icon {
        padding: 0;
        margin-left: 0.45em;
        svg {
          width: 1.8em;
          height: 1.8em;
          margin-top: 6px;
          path {
            fill: $svg-color-dark;
          }
          rect,
          ellipse {
            fill: $svg-color-light;
          }
        }
        &.selected {
          background: $button-icon-hover;
          border-radius: 3px;
        }
      }
    }
  }

  .divider {
    width: 1px;
    height: 30px;
    background: $tab-border;
    border-radius: 24px;
    margin: 0 20px;
  }

  @include sm {
    .dashboard-rate {
      justify-content: center;
    }
  }
}

.acceptance-rate-tip {
  max-width: 360px !important;
  .ant-tooltip-inner {
    padding: 11px;
    border-radius: 6px;
  }
}

.handle-user-lock {
  .dashboard-filter {
    .item-filter-menu {
      .ant-col {
        .date-picker-col,
        .search-filter-col {
          opacity: 0.4;
          .anticon, .ant-picker-input input, .ant-picker-separator, .ant-picker-suffix {
            pointer-events: none !important;
          }
        }
      }
    }
  }
}

.ant-popover.teams-dashboard-popup {
  width: 20em;
  text-align: center;
  .ant-popover-inner-content {
    padding: 20px 16px!important;
    .ant-col {
      .ant-divider {
        background: $secondary-color;
        width: 2em;
        min-width: 2em;
        height: 1px;
        padding: 0 10px;
        margin: 15px 7px !important
      }
      p {
        text-align: left !important;
        padding-left: 7px;
        color: $primary-color-text;
      }
      h3 {
        margin-top: 2em;
        color: $primary-color-text;
      }
      .ant-btn {
        background: $secondary-color;
        height: 32px;
        border: 2px solid $secondary-color;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        padding: 0px 15px;
        margin-top: 1em;
      }
    }
  }
}
.sort-menu {
  width: 260px;

  .ant-menu-item {
    align-content: center;

    .menu-item_ {
      padding: 0 1rem !important;
    }

    .ant-radio-wrapper {
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-left: 0;

      span {
        padding: 0;
      }

      .ant-radio {
        .ant-radio-inner {
          border: none !important;
          background-color: transparent !important;
          box-shadow: none !important;

          &:after {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiByeD0iOCIgZmlsbD0iIzA1MDM0RCIvPgo8cGF0aCBkPSJNNCA4LjVMNyAxMUwxMiA1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
            background-size: cover;
            background-color: transparent !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            transition: opacity 0.5s ease;
          }
        }
      }
    }
  }
}