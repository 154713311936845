@import "styles/theme.scss";

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1)+$replace +str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;

  // $number must be a number
  @if type-of($number) !=number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }

  // $digits must be a unitless number
  @if type-of($digits) !=number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  }

  @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }

  @if $digits >0 {
    @for $i from 1 through $digits {
      $n: $n * 10;
    }
  }

  $value: 0;

  @if $mode ==round {
    $value: round($number * $n) / $n;
  }

  @else if $mode ==ceil {
    $value: ceil($number * $n) / $n;
  }

  @else if $mode ==floor {
    $value: floor($number * $n) / $n;
  }

  @else {
    @warn '#{ $mode } is undefined keyword.';
    $value: $number;
  }

  @if (str-index(qoute($value), '.')) {
    @return $value;
  }

  @else {
    @return ($value)+'.0';
  }
}

@mixin attr-x($attr,
  $attr-count: 10,
  $attr-steps: 10,
  $unit: '%',
  $decimal: 0,
  $attr-type: '-',
  $attr-value-steps: 1) {
  $attr-list: null;

  @if $attr-type =='-' {
    @for $i from 1 through $attr-count {
      $attr-value: $attr-steps * $i;

      @if ($decimal !=0) {
        $attr-value: decimal-round($attr-steps * $i, $decimal);
      }

      .#{$attr}-#{$attr-value} {
        @if $attr =='intent-left' {
          margin-left: #{$attr-value}#{$unit};
        }

        @else if $attr =='intent-right' {
          margin-right: #{$attr-value}#{$unit};
        }

        @else {
          #{$attr}: #{$attr-value}#{$unit};
        }
      }

      $attr-list: append($attr-list, unquote('.#{$attr}-#{$attr-value}'), comma);
    }
  }

  @else {
    @for $i from 1 through $attr-count {
      $attr-value: $attr-steps * ($attr-value-steps * $i) * 100;
      $attr-label: str-replace(quote(decimal-round($attr-value-steps * $i, $decimal)), '.', '-');

      &#{$attr-type}#{$attr-label} {
        #{$attr}: #{$attr-value}#{$unit};
      }

      $attr-list: append($attr-list, unquote('&#{$attr-type}#{$attr-label}'), comma);
    }
  }
}



.section-wrapper {

  // common code for draft and preview
  blockquote {
    padding: 10px 20px;
    border-left: 5px solid #e2e2e2;
    font-size: 18px;
    line-height: 22px;
  }

  .line-height {
    @include attr-x('line-height', 180, 0.01, '', 1, '__', 0.1);
  }

  // intent (left, right)
  @include attr-x('intent-left', 26, 1, 'em');
  @include attr-x('intent-right', 26, 1, 'em');
}