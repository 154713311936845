@import 'styles/theme.scss';

.simple-info-modal.totp-modal {
  width: 45em !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  top: 0;

  .ant-modal-content {
    border-radius: 6px;

    .ant-modal-body {
      padding: 2.5em;

      @include sm {
        padding: 1.5em
      }

      .title {
        margin-top: 0;
        justify-content: flex-start;
        text-align: start;
      }

      .divider {
        margin: 1em 0;
      }

      .body {
        margin-bottom: 4.5em;

        .ant-form {
          .ant-form-item {
            margin-bottom: 0;
          }

          .ant-row {
            .ant-form-item-label {
              color: $label-color-light;
              letter-spacing: 1px;
            }

            .ant-form-item-control {
              .ant-form-item-control-input {
                .ant-form-item-children-icon {
                  top: 35%
                }

                .ant-form-item-control-input-content {
                  input {
                    border: 1px solid $primary-color-light;
                    border-radius: 4px;
                    color: #05034d;
                  }

                  .ant-select {
                    .ant-select-selector {
                      border: 1px solid $primary-color-light;
                      border-radius: 4px;

                      input {
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .button-wrapper {
        justify-content: right;
        padding: 0;

        button.ant-btn {
          height: 44px;

          @include sm {
            &:last-child {
              margin-left: 0;
              width: 100%;
            }

            &.cancel {
              display: none
            }
          }
        }

        .confirm {
          span {
            text-transform: capitalize;
          }

          margin-left: 1em;
          width: 280px
        }

        .cancel {
          margin-right: 1em;
        }
      }
    }
  }

  .totp-step {
    text-align: start;
    background-color: #F5F6FA;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 0.6rem;

    .step-title {
      font-size: 16px;
      color: #05034D;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include sm {
        display: block;
      }

      .qr-container {
        border-radius: 10px;

        img {
          border-radius: 13px;
          border: 2px solid #EDEAF2;
        }
      }

      .or-divider {
        padding: 0 20px;
        font-size: 12px;
        font-weight: 500;
        color: #5A6185;
        text-transform: uppercase;

        @include sm {
          padding: 8px 0;
          visibility: hidden;
        }
      }

      .step-code {
        .step-description {
          color: #4F4E7B;
          font-weight: 300;
          font-size: 13px;
        }

        .form-help {
          font-size: 10px;
          color: #4F4E7B;
        }

        .ant-form-item-explain {
          color: #ff524e;
        }
      }
    }

    .qr-container {
      .image {
        width: 130px;
      }
    }

    .form-item,
    .ant-form-item-control-input {
      .ant-input-affix-wrapper {
        min-width: unset;
        padding: unset;
        color: unset;
        font-size: unset;
        line-height: unset;
        background-color: unset;
        background-image: unset;
        border: unset;
        border-radius: 4px;

        .ant-input-suffix {
          margin-left: -28px;
        }
      }

      input {
        padding: 14px;
        border-radius: 4px;
        height: 45px;
        border: 1px solid #EDEAF2 !important;

        &.auth-code {
          border: none;
          background-color: #e7e9ef;
          color: #05034D;
          font-size: 16px;
          font-weight: 600;
          pointer-events: none;
        }
      }
    }

    .copy-totp-button {
      background-color: #fff;
      width: 45px;
      height: 45px;
      padding: 12px;
    }
  }

  .autocomplete-wrapper {
    width: 100%;
  }

  &.hebrew,
  &.arabic {

    .autocomplete-wrapper input,
    .ant-form-item-label {
      text-align: right;
    }

    .ant-input {
      direction: rtl;
    }

    .ant-select-selector {
      text-align: right !important;
    }
  }
  .confirm {
    background-color: #f03f3b !important;
    color: #fff !important;
    &:hover {
      filter: brightness(1.1);
      opacity: 1 !important;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:disabled:hover {
      opacity: 0.6 !important;
    }
  }
}

.ant-select-item-option.hebrew,
.ant-select-item-option.arabic {
  direction: rtl;
}