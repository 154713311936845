@import 'styles/theme.scss';
.multi-select {
  background: $white-color;
  box-shadow: 0px 0.625em 1.25em rgba(4, 3, 49, 0.15);
  border-radius: 0.25em;
  .menu-header {
    padding: 1em 1.2em 1em;
    .title {
      font-size: $label-font-size;
      font-weight: 600;
      color: $primary-color-dark;
    }
  }
  .border-bottom{
    border-bottom: 1px solid #e2e3e8;
  }
  .menu-item {
    padding: 0 !important;
    .menu-item_ {
      padding: 0 1rem;
    }
  }
  .item-text {
    font-size: $label-font-size;
    color: $primary-color-dark;
    &.hide-multi-checkbox {
      .ant-checkbox-inner {
        display: none;
        border-color: none !important;
        &:hover {
          border-color: none !important;
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    padding: 0.125em;
  }
  .ant-checkbox + span {
    padding-right: 0.75em;
    padding-left: 0.75em;
    text-transform: capitalize;
  }
  .ant-menu-item {
    align-content: center;
    &:hover, &.ant-menu-item-active {
      background-color: #f5f5f5 !important;
    }
  }
}
