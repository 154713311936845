@import "styles/theme.scss";

.preview-proposal-modal {
  .publish-content {
    background-color: lightgray;
    overflow-y: auto;
    border: 1px solid lightgray;
    max-height: calc(100vh - 120px);
    #section-content-container, > div {
      pointer-events: none;
    }
  }
  .preview-cover-modal {
    width: 100%;
    max-height: calc(100vh - 100px);
    text-align: center;
    .ant-image-img{
      height: calc(100vh - 120px);
      object-fit: contain;
    }
  }
  .preview-media-modal {
    width: 100%;
    height: 75vh;
  }
  .proposal-button-right {
    float: right !important;
    margin-right: 40px !important;
    &:hover {
      background-color: #F5F4F9 !important;
      border: 0;
      border-radius: 4px !important;
      color: $heading-color !important;
    }
  }
}

.title.hebrew {
  direction: rtl;
}
